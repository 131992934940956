import { ComponentType } from "react";
import { MortgageStepBaseDirector, IMFormStepBuilder, StepView } from ".";
import {
  BasicPersonalDataForm,
  ChildrenForm,
  CurrentHouseSituationForm,
  MaritalStatusForm,
  ResidenceForm,
  ResidencePermitForm,
  SolicitorTypeForm,
  UserWorkForm,
} from "../components/formSteps";
import { MortgageFormData } from "shared/types/postMortgageData";

export class PersonalDataBuilder implements IMFormStepBuilder {
  private initialStepView: Pick<
    StepView<MortgageFormData>,
    "group" | "subGroup"
  > = {
    group: "form",
    subGroup: "personalData",
  };
  _stepViews: StepView<MortgageFormData>[] = [];
  // maybe all component use some global state like react context api
  addStep(
    Component: ComponentType<any>,
    accesor?: StepView<MortgageFormData>["accesor"]
  ) {
    this._stepViews.push({
      ...this.initialStepView,
      component: Component,
      accesor,
    });
    return this;
  }
  addSolicitorType(): PersonalDataBuilder {
    this.addStep(SolicitorTypeForm, 
    //   {
    //   path: "solicitor_type",
    //   label: "Tipo de solicitud",
    // }
    );
    return this;
  }
  addBasicPersonalData(): PersonalDataBuilder {
    this.addStep(BasicPersonalDataForm, [
      {
        path: "user.name",
        label: "Nombre",
      },
      {
        path: "user.surname",
        label: "Apellido",
      },
      {
        path: "user.email",
        label: "Correo electronico",
      },
      {
        path: "user.birthdate",
        label: "Fecha de Nacimiento",
      },
      {
        path: "user.dni",
        label: "DNI o NIE",
      },
      {
        path: "user.phone",
        label: "Móvil",
      },
    ]);
    return this;
  }
  addMaritalStatus(): PersonalDataBuilder {
    this.addStep(MaritalStatusForm, {
      path: "user.marital_status",
      label: "Estado Civil",
    });
    return this;
  }
  addResidence(): PersonalDataBuilder {
    this.addStep(ResidenceForm, {
      path: "user.residence",
      label: "País de residencia",
    });
    return this;
  }
  addResidencePermit(): PersonalDataBuilder {
    this.addStep(ResidencePermitForm, {
      path: "user.residence_permit",
      label: "Permiso de residencia",
    });
    return this;
  }
  addChildren(): PersonalDataBuilder {
    this.addStep(ChildrenForm, {
      path: "user.children",
      label: "Hijos o persona a cargo",
    });
    return this;
  }
  addCurrentHouseSituation(): PersonalDataBuilder {
    this.addStep(CurrentHouseSituationForm, {
      path: "user.current_house_situation",
      label: "Situación de vivienda actual",
    });
    return this;
  }
  addUserWork(): PersonalDataBuilder {
    this.addStep(UserWorkForm, {
      path: "user.work",
      label: "Situación laboral",
    });
    return this;
  }

  build(): StepView<MortgageFormData>[] {
    return this._stepViews;
  }
}

export class PersonalDataDirector implements MortgageStepBaseDirector {
  private builder: PersonalDataBuilder;

  constructor(builder: PersonalDataBuilder) {
    this.builder = builder;
  }

  buildNewMortgage() {
    this.builder.addSolicitorType();
    this.builder.addBasicPersonalData();
    this.builder.addMaritalStatus();
    this.builder.addResidence();
    this.builder.addResidencePermit();
    this.builder.addChildren();
    // this.builder.addCurrentHouseSituation();
    this.builder.addUserWork();
  }

  buildImproveMortgage() {
    this.buildNewMortgage();
  }

  buildRaiseCapital() {
    this.buildNewMortgage();
  }
  buildAutopromotor() {
    this.buildNewMortgage();
  }
}
