import { dniSchema, requiredStringSchema } from "utils/yupValidation";
import * as yup from "yup";

// esto puede que cambie en el futuro
const imgSchema = (label = "frontal") =>
  yup.mixed().test({
    name: "testfile",
    message: `Por favor ingrese una foto ${label} de su DNI o NIE`,
    test: (value) => {
      return Boolean(value);
    },
  });

export const stepFiveValidationSchema = yup.object().shape({
  solicitor_type: requiredStringSchema("Por favor seleccione una opción"),
  user: yup.object().shape({
    dni_file_front: imgSchema(),
    dni_file_back: imgSchema("del reverso"),
  }),
  user_2: yup.object().when("solicitor_type", {
    is: (val: any) => {
      return Number(val) === 2;
    },
    then(schema) {
      return yup.object().shape({
        dni_file_front: imgSchema(),
        dni_file_back: imgSchema("del reverso"),
      });
    },
    otherwise(schema) {
      return schema.nullable();
    },
  }),
});
