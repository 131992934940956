import {
  FunctionComponent,
  ReactElement,
  useState,
  useEffect,
} from "react";
import AppBar from "@mui/material/AppBar";
import Link from "@mui/material/Link";

import { Toolbar, IconButton, styled } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { useLocalStorage } from "../../../shared/hooks";

import subject, {
  OPEN_LEFT_BAR,
} from "../../../utils/subjects";
import styles from "./styles.module.scss";
import BrowserVerAlert from "./components/BrowserVerAlert";


const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

export interface ITopBarProps {
  allowLeftBarMenu: boolean;
  showAuthControls: boolean;
  imageCompany?: string;
  linkColibid?: string;
}
const TopBar: FunctionComponent<ITopBarProps> = ({
  allowLeftBarMenu,
  showAuthControls,
  imageCompany,
  linkColibid,
}): ReactElement<any> => {
  const [ssid] = useLocalStorage("access_token");
  const [role] = useLocalStorage("role");
  const [link, setLink] = useState("/");

  useEffect(() => {
    if (linkColibid) setLink(linkColibid);
  }, []);

  useEffect(() => {
    if (ssid) {
      if (role === "client") {
        setLink("/client/home");
      }

      if (role === "broker") {
        setLink("/broker/applications");
      }
    }
  }, [ssid, role]);

  return (
    <>
      <AppBar position="fixed" color="secondary" className={styles.MainTopBar}>
        <Toolbar>
          {allowLeftBarMenu ? (
            <IconButton
              size="large"
              edge="start"
              color="primary"
              aria-label="menu"
              sx={{ mr: 2 }}
              className={styles.MenuIcon}
              onClick={() => subject.send(OPEN_LEFT_BAR, true)}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <IconButton
              size="large"
              edge="start"
              color="primary"
              aria-label="menu"
              sx={{ mr: 2 }}
              className={styles.MenuIconHidden}
            >
              <MenuIcon />
            </IconButton>
          )}

          {imageCompany && (
            <div
              className={styles.Logo}
              style={{
                flexGrow: 1,
                textAlign: imageCompany ? "end" : "center",
              }}
            >
              <Link href={link}>
                <img src={imageCompany} alt="Colibid" />
              </Link>
            </div>
          )}

          <div
            className={styles.Logo}
            style={{ flexGrow: 1, textAlign: imageCompany ? "end" : "center" }}
          >
            <Link href={link}>
              <img
                src={
                  imageCompany
                    ? "https://colibid.fra1.digitaloceanspaces.com/colibid%2Fpublic%2Fassets%2Fimg%2FPOWERED_BY_COLIBID.png"
                    : "/assets/img/logo.svg"
                }
                alt="Colibid"
              />
            </Link>
          </div>
        </Toolbar>
        

      {/* <BrowserVerAlert /> */}
      </AppBar>
      <Offset id='offset'/>
    </>
  );
};

export default TopBar;
