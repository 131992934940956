import { FieldPath, FieldValues, Path, Resolver } from "react-hook-form";
import { AvailableDirectAccess } from "shared/components/DirectAccess";
import { InputOption, NumericString } from "shared/types/misc";
import {
  ExpensesList,
  MortgageFormData,
  PostMortgageData,
  TempUploadItem,
  UserForm,
} from "shared/types/postMortgageData";
import { getCountryInfo } from "utils";
import { EmploymentSituations, MortgageMode } from "../types";
import {
  COLIBID_FEE,
  COLIBID_FEE_AUTOPROMOTOR,
} from "shared/constants/mortgageForm";
import { M_MODE } from "shared/constants/localStorage";
import { getValueInObjectWithDotNotation } from "utils/helpers";
import { YesNoOption } from "shared/types/Enums";
import { MAX_MORTGAGE_RATE } from "shared/constants/mortgageForm";
// import { sub } from "date-fns";

const userInitialState: UserForm = {
  name: "",
  surname: "",
  birthdate: "",
  dni: "",
  type_dni: "DNI",
  residence: "",
  residence_permit: "",
  work: "",
  work_date: "",
  email: "",
  phone: "",
  dni_file_front: "",
  dni_file_back: "",
  salary: "",
  profession: "",
  work_init_month: "",
  work_init_year: "",
  marital_status: "",
  children: "",
  current_house_situation: "",
  has_last_year_tax: "",
};

export const getInitialFormData = ({
  name,
  lastname,
  email,
  mortgageMode,
  phone,
}: {
  name?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  mortgageMode?: number;
}): MortgageFormData => {
  return {
    mortgage: { mode: mortgageMode || 0 },
    solicitor_type: "",
    user: {
      ...userInitialState,
      name: (name || "").split(" ")[0],
      surname: lastname || "",
      email: email || "",
      phone: phone || "",
    },
    user_2: userInitialState,
    revenue: {
      incomes_list: [],
      expenses_list: [],
      coli_last_3_file: "",
      coli_emp_contract: "",
      coli_account_state: "",
      monthly: "",
    },
    data: {
      interest_type: "todos",
      interest_type_remortgage: "",
      amount: "",
      period: "",
      interest_rate: "",
      pending_amount: "",
      remortgage_laps_years: "",
      mortgage_rate: "",
      tax_and_expenses: "",
      fund_used_for: "",
      debtRatio: 35,
      curr_mortgage_init_date: "",
      curr_original_amount: "",
    },
    property: {
      hasEndorsement: "",
      constructionType: "",
      area: "",
      type: "",
      province: "",
      community: "",
      city: "",
      zipcode: "",
      address: "",
      value: "157421",
      notasimple: "",
      arras: "",
      reserva: "",
      appraisal: "",
      house_situation: "",
      project_approved: {
        value: "",
        selected_doc_type: "",
        project_obra_visado: "",
        nota_simple_parcela: "",
      },
      service_request:{
        solar_panel: {
          req: false
        }
      }
    },
    property_existence: "",
    current_bank: "",
    customer_application_situation: {
      other_active_applications: "",
      types_entities: [],
      financials_entities: [],
    },
    mortgage_initial: "0",
    temp_uploads: [],
    form_init_date: "",
  };
};

export const getUserFormLabel = (isUser2: boolean = false, label: string) => {
  if (isUser2) {
    return `${label} del acompañante`;
  }
  return label;
};

export const getUserFormName = ({
  isUser2 = false,
  name,
}: {
  isUser2: boolean;
  name: FieldPath<MortgageFormData>;
}): FieldPath<MortgageFormData> => {
  if (isUser2) {
    const splitedName = name.split(".");
    const user2 = splitedName[0].concat("_2");
    const newUserName = user2 + "." + splitedName[1];
    return newUserName as FieldPath<MortgageFormData>;
  }
  return name;
};

export const isFormAFuncionario = (mortgageFormData: MortgageFormData) => {
  const solicitorType = mortgageFormData.solicitor_type;
  const userWork = mortgageFormData.user.work;
  const user2Work = mortgageFormData.user_2.work;
  return isFuncionario(solicitorType, userWork, user2Work);
};

export const isFuncionario = (
  solicitorType?: string | number,
  userWork?: string | number,
  user2Work?: string | number
): boolean => {
  return (
    (solicitorType === "1" && userWork === "4") ||
    (solicitorType === "2" && (userWork === "4" || user2Work === "4"))
  );
};

export const getMortgageRatePercentageAllowed = (
  propertyType?: string | number
) => (Number(propertyType) === YesNoOption.Sí ? 90 : 60);

export const solicitorTypeOptions = [
  { value: "1", label: "Individual" },
  { value: "2", label: "Conjunta" },
];

export const EMPLOYMENT_SITUATIONS = [
  { id: "1", description: "Indefinido por más de 1 año" },
  { id: "8", description: "Indefinido entre 6 meses y un año" },
  { id: "2", description: "Indefinido por menos de 6 meses" },
  { id: "5", description: "Contrato Temporal" },
  { id: "10", description: "Funcionario / Interino" },
  { id: "4", description: "Funcionario de Carrera" },
  { id: "7", description: "Autónomo / Empresario" },
  { id: "6", description: "Pensionista" },
  { id: "3", description: "Desempleado" },
  { id: "9", description: "Otro" },
];

export const propertyExistenceOptions = [
  { value: "1", label: "Obra Nueva" },
  { value: "2", label: "Existente" },
  // { value: 3, label: "Autopromotor" },
];

export const constructionTypeOptions = [
  { value: "1", label: "Piso de una planta" },
  { value: "2", label: "Piso dúplex" },
  { value: "3", label: "Chalet adosado" },
  { value: "4", label: "Chalet independiente" },
];

export const getEmploymentSituationsByAge = (clientAge: number) => {
  return EMPLOYMENT_SITUATIONS.filter((e) => {
    if (e.id === "6") {
      return clientAge >= 60;
    }
    return true;
  });
};

export const isUserUnemployedById = (userEmploymentSituationId: number) => {
  return userEmploymentSituationId === EmploymentSituations.UNEMPLOYED;
};

export const isUserPensionistById = (userEmploymentSituationId: number) => {
  return userEmploymentSituationId === EmploymentSituations.PENSIONER;
};

export const HAS_CHILDREN_OPTIONS: InputOption[] = [
  { value: "0", label: "Ninguno" },
  { value: "1", label: "Uno" },
  { value: "2", label: "Dos" },
  { value: "3", label: "Tres" },
  { value: "4", label: "Cuatro" },
  { value: "6", label: "5 o más" },
];

export const ESTADO_CIVIL_OPTIONS: InputOption[] = [
  { value: "1", label: "Soltero" },
  { value: "2", label: "Casado" },
  { value: "3", label: "Divorciado" },
];

export const RESIDENCE_PERMITS: InputOption[] = [
  { value: "1", label: "Permanente" },
  { value: "2", label: "Temporal" },
];

export const CURRENT_HOUSE_SITUATION_OPTIONS: InputOption[] = [
  { value: "1", label: "Alquiler" },
  { value: "2", label: "Propiedad" },
  { value: "3", label: "Familiar" },
];

export const HOUSE_SITUATION_OPTIONS: InputOption[] = [
  { value: "2", label: "Tengo nota simple" },
  { value: "3", label: "Tengo una reserva" },
  { value: "4", label: "Tengo las Arras" },
  { value: "5", label: "Tengo la tasación" },
  { value: "1", label: "No tengo una vivienda identificada" },
];

type UploadsLabelAndPath<T extends FieldValues> = Record<
  string,
  { name: Path<T>; label: string }
>;

export const HOUSE_SITUATION_UPLOADS: UploadsLabelAndPath<MortgageFormData> = {
  "2": { name: "property.notasimple", label: "Subir nota simple (Opcional)." },
  "3": { name: "property.reserva", label: "Subir reserva (Opcional)." },
  "4": { name: "property.arras", label: "Subir Arras (Opcional)." },
  "5": { name: "property.appraisal", label: "Subir tasación (Opcional)." },
};
export const HOUSE_SITUATION_SLUG = [
  { value: "2", label: "nota simple", name: "notasimple" },
  { value: "3", label: "reserva", name: "reserva" },
  { value: "4", label: "Arras", name: "arras" },
];

export const getHouseSituationSlug = (value: string) => {
  return (
    HOUSE_SITUATION_SLUG.find((e) => e.value === value) || {
      value: "",
      label: "",
      name: "",
    }
  );
};

export const PROJECT_APPROVED_OPTIONS: InputOption[] = [
  { value: "1", label: "Nota simple de la parcela" },
  { value: "2", label: "Proyecto de obra visado" },
];
export const PROJECT_APPROVED_UPLOADS: UploadsLabelAndPath<MortgageFormData> = {
  "1": {
    name: "property.project_approved.nota_simple_parcela",
    label: "Subir nota simple de la parcela (Opcional).",
  },
  "2": {
    name: "property.project_approved.project_obra_visado",
    label: "Subir proyecto de obra visado (Opcional).",
  },
};

export const getOptionsLabel = ({
  options,
  value,
}: {
  options: InputOption[];
  value?: number | string;
}): string => {
  return options.find((e) => e.value === value?.toString())?.label || "";
};

export const getMortgageModeValue = (mode: AvailableDirectAccess) => {
  if (mode === AvailableDirectAccess.NEW_MORTGAGE) return 1;
  if (mode === AvailableDirectAccess.IMPRO_MORTGAGE) return 2;
  if (mode === AvailableDirectAccess.RAISE_CAPITAL) return 3;
  if (mode === AvailableDirectAccess.AUTOPROMOTOR) return 4;
  throw new Error(
    "args only accept enums NEW_MORTGAGE, IMPRO_MORTGAGE, RAISE_CAPITAL, AUTOPROMOTOR and other mortgage mode"
  );
};

export const getGA_actionLabel = (mode: AvailableDirectAccess) => {
  if (mode === AvailableDirectAccess.NEW_MORTGAGE)
    return "(Step 1) Clicked on create new mortgage";
  if (mode === AvailableDirectAccess.IMPRO_MORTGAGE)
    return "(Step 1) Clicked on re-mortgage";
  if (mode === AvailableDirectAccess.RAISE_CAPITAL)
    return "(Step 1) Clicked on raise";
  if (mode === AvailableDirectAccess.AUTOPROMOTOR)
    return "(Step 1) Clicked on autopromotor";
  throw new Error(
    "args only accept enums NEW_MORTGAGE, IMPRO_MORTGAGE, RAISE_CAPITAL, AUTOPROMOTOR and other mortgage mode"
  );
};

export function getTaxAndExpenses(
  propertyValue: NumericString,
  mortgageMode: number
) {
  if (mortgageMode === 4)
    return Number(propertyValue) * COLIBID_FEE_AUTOPROMOTOR;
  return Number(propertyValue) * COLIBID_FEE;
}

export function calcDataAmount({
  propertyValue: propertyVal,
  mortgageInitial,
}: {
  propertyValue: NumericString;
  mortgageInitial: NumericString;
}) {
  // const fees = getTaxAndExpenses(propertyVal);
  // return Number(propertyVal) - (Number(mortgageInitial) - fees);
  return Number(propertyVal) - Number(mortgageInitial);
}

export type GetMortgageRateProps = {
  mortgageMode: number;
  /**is data amount or new data amount  if mortgageMode !== 1 */
  numerator: NumericString;
  propertyValue: NumericString;
  mortgageInitial?: NumericString;
};
/**this calc will not work for mortgage mode 3, is other calc  */
export function getMortgageRate({
  mortgageMode,
  numerator,
  propertyValue: propertyVal,
  mortgageInitial,
}: GetMortgageRateProps) {
  const newNumerator =
    mortgageMode === 1 && mortgageInitial
      ? calcDataAmount({ propertyValue: propertyVal, mortgageInitial })
      : Number(numerator);
  const mortgageRate = (Number(newNumerator) / Number(propertyVal)) * 100;
  return isNaN(mortgageRate) ? 0 : Math.ceil(mortgageRate);
}

type MaxMortgageInitialProps = {
  isFuncionario: boolean;
  propertyValue: NumericString;
  propertyType: NumericString;
};
export function maxMortgageInitial({
  isFuncionario,
  propertyType,
  propertyValue,
}: MaxMortgageInitialProps) {
  return isFuncionario
    ? Number(propertyValue)
    : (Number(propertyValue) * getMortgageRatePercentageAllowed(propertyType)) /
        100;
}
export function minMortgageInitial(
  mortgageFormData: MortgageFormData,
  newPropertyValue?: NumericString
) {
  const mortgageMode = mortgageFormData.mortgage.mode;
  const propertyValue = newPropertyValue || mortgageFormData.property.value;
  const propertyType = mortgageFormData.property.type;
  if (mortgageMode === MortgageMode.AUTOPROMOTOR) {
    if (Number(propertyType) === YesNoOption.No) {
      return Number(propertyValue) * 0.3;
    }
    return Number(propertyValue) * 0.2;
  } else {
    if (Number(propertyType) === YesNoOption.No) {
      return Number(propertyValue) * 0.4;
    }
    return Number(propertyValue) * 0.2;
  }
}
export function getMortgageInitialRange(
  mortgageFormData: MortgageFormData,
  newPropertyValue?: NumericString
) {
  const isFuncionario = isFormAFuncionario(mortgageFormData);
  const propertyType = mortgageFormData.property.type;
  const propertyValue = newPropertyValue || mortgageFormData.property.value;
  const maxAmount = maxMortgageInitial({
    isFuncionario,
    propertyType,
    propertyValue,
  });
  const minAmount = minMortgageInitial(mortgageFormData, newPropertyValue);
  return { minAmount, maxAmount };
}

export function isMortgageModeResolver(
  resolver: (
    arg1: any,
    arg2: any,
    arg3: any
  ) => any | ((mortgageMode: number) => Resolver<any>)
): resolver is (mortgageMode: number) => Resolver<any> {
  return resolver.length === 1;
  // return typeof resolver === "function";
}

export function getM_ModeParams() {
  const searchParams = new URLSearchParams(window.location.search);
  const m_mode = localStorage.getItem(M_MODE) || searchParams.get(M_MODE);
  localStorage.removeItem(M_MODE);
  return m_mode;
}

const tempUploadsParams = [
  {
    property: "property.reserva",
    slug: "coli_reserva",
  },
  {
    property: "property.arras",
    slug: "coli_arras",
  },
  {
    property: "property.notasimple",
    slug: "coli_notasimple",
  },
  {
    property: "property.appraisal",
    slug: "coli_tasadora",
  },
  {
    property: "property.project_approved.nota_simple_parcela",
    slug: "coli_parcela",
  },
  {
    property: "property.project_approved.project_obra_visado",
    slug: "coli_proyecto_obra_visado",
  },
];
export const prepareTempUploads = (data: PostMortgageData) => {
  let tU: TempUploadItem[] = [];

  tempUploadsParams.forEach((el) => {
    const fileUrl = getValueInObjectWithDotNotation(
      data.mortgage_data,
      el.property
    );

    if (fileUrl !== "") {
      tU.push({
        slug: el.slug,
        url: fileUrl,
      });
    }
  });

  return tU;
};

export function getAllowedMortgageRate(m: MortgageFormData): number {
  const isFuncionario = isFormAFuncionario(m);
  if (isFuncionario) {
    return 100;
  }
  const mortgageMode = m.mortgage.mode;
  if (mortgageMode === MortgageMode.REMORTGAGE) {
    return MAX_MORTGAGE_RATE.REMORTGAGE;
  }

  if (mortgageMode === MortgageMode.NEW_MORTGAGE) {
    return MAX_MORTGAGE_RATE.NEW_MORTGAGE;
  }

  const propertyType = m.property.type;
  if (mortgageMode === MortgageMode.AUTOPROMOTOR) {
    if (Number(propertyType) === YesNoOption.No) {
      return 70;
    }
    return 80;
  }
  return getMortgageRatePercentageAllowed(propertyType);
}

export function validarArregloDeudas(objeto: ExpensesList): boolean {
  if (
    typeof objeto.solicitor === undefined ||
    typeof objeto.debt_monthly === undefined ||
    typeof objeto.debt_total === undefined ||
    typeof objeto.debt_duration === undefined
  ) {
    return false;
  }

  return true;
}
