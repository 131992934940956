import * as yup from "yup";
import { formatCurrency, validateSpanishDNI } from "./helpers";
import { isValidPhoneNumber } from "react-phone-number-input";

export const aboveZeroTest: yup.TestConfig<any, any> = {
  name: "notZero",
  message: "Por favor ingrese un monto mayor a 0",
  test: (value: string) => Number(value) > 0,
};

export const rangeValidation = (
  min: number,
  max: number,
  msg?: (val: any) => string
): yup.TestConfig<any, any> => ({
  name: `range`,
  message: (val) =>
    msg ? msg(val) : `El valor debe estar entre ${min} y ${max}`,
  test: (val) => {
    const value = Number(val);
    return value >= min && value <= max;
  },
});

export const greaterThan = (
  n: number = 100000,
  msg?: (val: any) => string
): yup.TestConfig<any, any> => ({
  name: `gt${n}`,
  message: (val) =>
    msg
      ? msg(val)
      : `El importe maximo a solicitar es de: ${formatCurrency(n)}.`,
  test: (testVal) => {
    return Number(testVal) <= n;
  },
});

export const lessThan = (
  n: number = 65000,
  msg?: (val: any) => string
): yup.TestConfig<any, any> => ({
  name: `lt${n}`,
  message: (val) =>
    msg
      ? msg(val)
      : `El importe mínimo a solicitar es de: ${formatCurrency(n)}.`,
  test: (testVal) => {
    return Number(testVal) >= n;
  },
});
// const noSpaceRegex = /^(\S+$)/;
export const REQ_MSG =
  "Por favor rellene el campo con la información solicitada";

export const selectAOptionSchema = (
  msg: string = "Por favor introduzca situación laboral"
) => yup.string().required(msg).nullable();

export const requiredStringSchema = (reqMsg: string = REQ_MSG) =>
  yup.string().required(reqMsg).nullable();

export const nameLastNameSchema = (msg?: string) =>
  requiredStringSchema(msg)
    .max(60, ({ max }) => `No puedes exceder mas de ${max} caracteres`)
    .matches(
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
      {
        message: "El valor introducido no es válido",
      }
    );

export const requiredStringOnlyAlphabetSchema = (msg?: string) =>
  requiredStringSchema(msg).matches(/^[a-zA-Z ]*$/, {
    message: "Solo se acepta letras del alphabeto",
    excludeEmptyString: true,
  });

export const emailSchema = requiredStringSchema(
  "Por favor ingresa un correo válido"
).email("Por favor ingresa un correo válido");

// const phoneRegExp = /^[679]{1}[0-9]{8}$/;
export const phoneSchema = requiredStringSchema(
  "Por favor introduzca un número de teléfono"
).test({
  name: "withReactPhoneNumberInput",
  message: "El formato parece ser incorrecto a su país",
  test: (val) => {
    return isValidPhoneNumber(val || "");
  },
});

export const dniSchema = requiredStringSchema(
  "Por favor introduzca un DNI o NIE"
).test("is-valid-dni", "Por favor ingrese un DNI o NIE válido", (value) =>
  validateSpanishDNI(value)
);

export const convertToNumericValue = (
  value: string | null | undefined
): number | null => {
  if (value) {
    return parseFloat(value.replace(",", "."));
  }
  return null;
};

export const isValidNumberWithCommaOrDot = (
  value: string | null | undefined
): boolean => {
  const numericValue = convertToNumericValue(value);
  return numericValue !== null && !isNaN(numericValue);
};

export const isGreaterThan =
  (min: number) =>
  (value: string | null | undefined): boolean => {
    const numericValue = convertToNumericValue(value);
    return numericValue !== null && numericValue > min;
  };

export const isLessThan =
  (max: number) =>
  (value: string | null | undefined): boolean => {
    const numericValue = convertToNumericValue(value);
    return numericValue !== null && numericValue < max;
  };
