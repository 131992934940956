import { MenuItem, Box, Grid, Typography } from "@mui/material";


import { Residence } from "shared/types/postMortgageData";
import { sub } from "date-fns";
import { COUNTRIES } from "shared/constants/countries";
import FormFields from "shared/components/FormFields";
import { getUserProfessions } from "utils/helpers";
import { EmploymentSituations } from "../../types";
import { getUserFormName, EMPLOYMENT_SITUATIONS, HAS_CHILDREN_OPTIONS, ESTADO_CIVIL_OPTIONS, CURRENT_HOUSE_SITUATION_OPTIONS } from "../../utils";

const renderMonthsOptions = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
].map((m, idx) => {
  // Valid id for month 1 to 9 including a 0 at the beginning "01", "02", ...
  const value = idx < 9 ? `0${idx + 1}` : `${idx + 1}`;
  return (
    <MenuItem value={value} key={m}>
      {m}
    </MenuItem>
  );
});

const renderIfWorkTrue = ({
  workSituations,
  userWork,
  userWorkMonth,
  userWorkYear,
  currentYear,
  userWorkDate,
}: {
  workSituations: string[];
  userWork: string | number;
  userWorkMonth: "user.work_init_month" | "user_2.work_init_month";
  userWorkYear: "user.work_init_year" | "user_2.work_init_year";
  currentYear: number;
  userWorkDate: "user.work_date" | "user_2.work_date";
}) => {
  const isUndefinedMoreThanOneYear =
    Number(userWork) === EmploymentSituations.UNDEFINED_MORE_THAN_ONE_YEAR;

  const today = new Date();
  const maxDate = isUndefinedMoreThanOneYear ? sub(today, { years: 1 }) : today;
  const shouldDisableYear = (y: Date) => {
    if (isUndefinedMoreThanOneYear) {
      const currYear = y.getFullYear();
      const todayYear = today.getFullYear();
      return currYear === todayYear;
    }
    return false;
  };

  return (
    workSituations.indexOf(userWork as string) !== -1 && (
      <>
        <Grid item xs={12} md={12}>
          <Typography variant="caption" display="block" gutterBottom>
            Incluya el mes y año de inicio de su situación actual
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormFields.DatePicker
            views={["year", "month"]}
            inputFormat="MMM / yyyy"
            name={userWorkDate}
            label="Mes y Año"
            userWork={{ userWorkMonth, userWorkYear }}
            disableFuture
            disableHighlightToday
            shouldDisableYear={shouldDisableYear}
            maxDate={maxDate}
          />
        </Grid>
      </>
    )
  );
};

const renderCountryAutocompleteInput = ({ name }: { name: string }) => (
  <FormFields.AutoCompleteInput
    options={COUNTRIES}
    name={name}
    label="País de Residencia"
    isOptionEqualToValue={(opt: Residence, val) => {
      return opt.name_es === val;
    }}
    renderOption={(props: any, option: Residence) => {
      return (
      <Box
        component="li"
        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
        {...props}
      >
        <img
          loading="lazy"
          width="20"
          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
          alt=""
        />
        {option.name_es}
      </Box>
    )
    }}
  />
);

export const renderUserInputs = ({
  isUser2 = false,
  userWork,
  currentYear,
}: {
  isUser2?: boolean;
  userWork: string | number;
  currentYear: number;
}) => {
  return (
    <>
      <Grid item xs={12} md={12}>
        <FormFields.TextFieldInput
          label="Nombre"
          name={getUserFormName({ isUser2, name: "user.name" })}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <FormFields.TextFieldInput
          label="Apellido"
          name={getUserFormName({ isUser2, name: "user.surname" })}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <FormFields.TextFieldInput
          label="DNI o NIE"
          name={getUserFormName({ isUser2, name: "user.dni" })}
          placeholder="X12345678A"
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <FormFields.DatePicker
          name={getUserFormName({ isUser2, name: "user.birthdate" })}
          label="Fecha de Nacimiento"
          disableFuture
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <FormFields.SelectInput
          name={getUserFormName({ isUser2, name: "user.work" })}
          label="Situación Laboral"
          menuItems={EMPLOYMENT_SITUATIONS.map((e) => {
            return {
              key: e.id,
              value: e.id,
              label: e.description,
            };
          })}
        ></FormFields.SelectInput>
      </Grid>

      {renderIfWorkTrue({
        // Valid ID Work situations
        workSituations: ["1", "2", "4", "5", "7", "8", "9", "10"],
        userWork,
        userWorkMonth: isUser2
          ? "user_2.work_init_month"
          : "user.work_init_month",
        userWorkYear: isUser2 ? "user_2.work_init_year" : "user.work_init_year",
        userWorkDate: isUser2 ? "user_2.work_date" : "user.work_date",
        currentYear,
      })}

      <Grid item xs={12} md={12}>
        {renderCountryAutocompleteInput({
          name: getUserFormName({ isUser2, name: "user.residence" }),
        })}
      </Grid>

      <Grid item xs={12} md={12}>
        <FormFields.AutoCompleteInput
          name={getUserFormName({ isUser2, name: "user.profession" })}
          label="Profesión"
          options={getUserProfessions().map((e) => e.description)}
          getOptionLabel={(option: string) => option}
          renderOption={(props: any, option: string) => (
            <Box component="li" {...props}>
              {option}
            </Box>
          )}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <FormFields.SelectInput
          name={getUserFormName({ isUser2, name: "user.children" })}
          label="¿Tienes hijos o personas a cargo?"
          menuItems={HAS_CHILDREN_OPTIONS.map((e) => {
            return {
              key: e.value,
              value: e.value,
              label: e.label,
            };
          })}
        ></FormFields.SelectInput>
      </Grid>

      <Grid item xs={12} md={12}>
        <FormFields.RadioInput
          row
          name={getUserFormName({ isUser2, name: "user.marital_status" })}
          label="Estado Civil"
          radioOptions={ESTADO_CIVIL_OPTIONS}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <FormFields.RadioInput
          row
          name={getUserFormName({
            isUser2,
            name: "user.current_house_situation",
          })}
          label="¿Cuál es su situación de vivienda actual?"
          radioOptions={CURRENT_HOUSE_SITUATION_OPTIONS}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Typography variant="caption" display="block" gutterBottom>
          Información de contacto
        </Typography>
      </Grid>

      <Grid item xs={12} md={12}>
        <FormFields.TextFieldInput
          label="Email"
          name={getUserFormName({ isUser2, name: "user.email" })}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <FormFields.PhoneNumberInput
          label="Móvil"
          name={getUserFormName({ isUser2, name: "user.phone" })}
        />
      </Grid>
    </>
  );
};
