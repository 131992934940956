import * as yup from "yup";
import {
  emailSchema,
  nameLastNameSchema,
  phoneSchema,
  requiredStringSchema,
  selectAOptionSchema,
} from "utils/yupValidation";
import { isValid } from "date-fns";
import { getAgeByBirth } from "utils";
import { USER_AGE_RANGE } from "shared/constants/mortgageForm";
import { validateSpanishDNI } from "utils/helpers";
import { isUserPensionistById, isUserUnemployedById } from "../utils";
import { TestContextExtended } from "../types";
import { MortgageFormData } from "shared/types/postMortgageData";
import _ from "lodash";

export const isUserUniqueByField = (
  userField: keyof MortgageFormData["user"],
): yup.TestConfig<any, any> => {
  return {
    name: "isUnique",
    message: (params) => {
      const user = params.path.includes("2") ? "acompañante" : "solicitante";
      return "No puede ser igual al " + user;
    },
    test: function () {
      const { from } = this as yup.TestContext<MortgageFormData> &
        TestContextExtended;
      const { value } = from[1];
      const solicitorType = value.solicitor_type;
      if (solicitorType === "2") {
        //check if dn is repeated
        const userVal = value.user[userField];
        const user2Val = value.user_2[userField];
        if (_.isString(userVal) && _.isString(user2Val)) {
          const isEqual = _.isEqual(
            userVal.toLocaleLowerCase(),
            user2Val.toLocaleLowerCase(),
          );
          return !isEqual;
        }
        return _.isEqual(userVal, user2Val);
      }
      return true;
    },
  };
};

const residenceSchema = yup
  .object()
  .shape({
    name_en: yup.string(),
    name_es: yup.string(),
    dial_code: yup.string(),
    code: yup.string(),
  })
  .nullable()
  .required("Por favor introduzca un país de residencia");

const dniSchema = requiredStringSchema(
  "Por favor introduzca un DNI o NIE",
).test("is-valid-dni", "Por favor ingrese un DNI o NIE válido", (value) =>
  validateSpanishDNI(value),
);

const workInitMonthSchema = yup.string().when("work", {
  is(val: string) {
    return (
      isUserPensionistById(Number(val)) || isUserUnemployedById(Number(val))
    );
  },
  then(schema) {
    return schema.nullable();
  },
  otherwise(schema) {
    return schema.required("Por favor seleccione un mes").nullable();
  },
});

const workInitYearSchema = yup.string().when("work", {
  is(val: string) {
    return (
      isUserPensionistById(Number(val)) || isUserUnemployedById(Number(val))
    );
  },
  then(schema) {
    return schema.nullable();
  },
  otherwise(schema) {
    return requiredStringSchema()
      .test({
        name: "currentYearOrMore",
        test: (val) => val?.toString().length === 4,
        message: "La fecha debe tener al menos cuatro dígitos",
      })
      .test({
        name: "maxCurrYear",
        test: (val) => {
          const currYear = new Date().getFullYear();
          return currYear >= Number(val);
        },
        message: "La fecha tiene que ser el presente año o anterior",
      });
  },
});

// .test({
//   name: "yearRange",
//   test: (val) => {
//     if (val) {
//       const year = val.toString();
//       return /^(19[5-9]\d|20[0-9]\d)$/.test(year);
//     }
//     return true;
//   },
//   message: "El rango del año debe ser desde 1950 a 2099",
// });

const dateSchema = requiredStringSchema("Por favor introduzca una fecha")
  .test("validDate", "Por favor introduzca una fecha válida", (val: any) => {
    const date = new Date(val);
    const validDate = isValid(date);
    return validDate;
  })
  .test(
    `gt${USER_AGE_RANGE.min}`,
    `Debes ser mayor a ${USER_AGE_RANGE.min} años`,
    (testVal: any) => {
      const diff = getAgeByBirth(testVal);
      return diff >= USER_AGE_RANGE.min;
    },
  )
  .test(
    `lt${USER_AGE_RANGE.max}`,
    `La edad máxima recomendada es de ${USER_AGE_RANGE.max} años de edad`,
    (testVal: any) => {
      const diff = getAgeByBirth(testVal);
      return diff < USER_AGE_RANGE.max;
    },
  );

// const workDate
export const userSchema = yup.object().shape({
  work: selectAOptionSchema(),
  work_date: yup.string().when("work", {
    is(val: string) {
      return (
        isUserPensionistById(Number(val)) || isUserUnemployedById(Number(val))
      );
    },
    then(schema) {
      return schema.nullable();
    },
    otherwise(schema) {
      return schema
        .required("Por favor seleccione un mes")
        .nullable()
        .test(
          "validDate",
          "Por favor introduzca una fecha válida",
          (val: any) => {
            const date = new Date(val);
            const validDate = isValid(date);
            return validDate;
          },
        );
    },
  }),
  work_init_month: workInitMonthSchema,
  work_init_year: workInitYearSchema,
  profession: selectAOptionSchema("Por favor introduzca su profesión"),
  name: nameLastNameSchema("Por favor introduzca un nombre"),
  surname: nameLastNameSchema("Por favor introduzca un apellido"),
  dni: dniSchema.test(isUserUniqueByField("dni")),
  email: emailSchema,
  residence: requiredStringSchema(),
  birthdate: dateSchema,
  phone: phoneSchema,
  // salary: requiredStringSchema(
  //   "Valor a ingresar debe ser cantidad neta mensual"
  // ),
  // dni_file_front: imgSchema(),
  // dni_file_back: imgSchema("del reverso"),
  marital_status: requiredStringSchema(),
  children: requiredStringSchema(),
  current_house_situation: requiredStringSchema(),
});

export const stepOneValidationSchema = yup.object().shape({
  solicitor_type: requiredStringSchema("Por favor seleccione una opción"),
  user: userSchema,
  user_2: yup.object().when("solicitor_type", {
    is: (val: any) => {
      return Number(val) === 2;
    },
    then(schema) {
      return userSchema;
    },
    otherwise(schema) {
      return schema.nullable();
    },
  }),
});
